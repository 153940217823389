<template>
  <div>
    <div v-if="thread" class="thread-container">
      <inbox-thread-banner :user="threadUser"></inbox-thread-banner>
      <inbox-messages class="messages" :is-sending="isSending" :thread-id="threadId"></inbox-messages>
      <div class="px-2 pb-2 pt-1">
        <inbox-text-input v-if="!isDeletedAccount" v-on:send="sendMessage"></inbox-text-input>
      </div>
    </div>
    <div v-else>
      <b-loading :is-full-page="false" :active="true"></b-loading>
    </div>
  </div>
</template>

<script>
import InboxThreadBanner from "@/web/components/inbox/InboxThreadBanner";
import InboxMessages from "@/web/components/inbox/InboxMessages";
import InboxTextInput from "@/web/components/inbox/InboxTextInput";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "InboxThread",

  components: { InboxThreadBanner, InboxMessages, InboxTextInput },

  data() {
    return {
      documentHidden: false,
      isSending: false,
    };
  },

  mounted() {
    this.loadInboxThread({ threadId: this.threadId });
  },

  methods: {
    ...mapActions("inboxThreads", ["loadInboxThread", "responseToThread"]),

    sendMessage(message) {
      if (message && message.length) {
        this.isSending = true;
        this.responseToThread({message, threadId: this.threadId})
          .finally(() => (this.isSending = false));
      }
    },
  },

  computed: {
    ...mapGetters("inboxThreads", ["threadById"]),
    ...mapGetters("currentUser", { currentUserUuid: "uuid" }),

    threadId() {
      return Number(this.$route.params.threadId);
    },

    thread() {
      return this.threadById(this.threadId);
    },

    threadUser() {
      return this.thread.users.find(user => user.user_identity_token !== this.currentUserUuid) || this.deletedUserPlaceholder;
    },

    deletedUserPlaceholder() {
      return {
        first_name: " ",
        last_name: " ",
        picture: null,
      };
    },

    isDeletedAccount() {
      return !this.threadUser.user_identity_token;
    }
  },
};
</script>

<style lang="scss" scoped>
.thread-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages {
  flex-grow: 1;
}
</style>
